import { Controller } from "stimulus";
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

//
// Hook up tippy.js in a turbolinks-friendly way
//
export default class TooltipsController extends Controller {
  static targets = [];

  connect() {
    tippy('[data-tippy-content]', {
      theme: "orange",
      placement: 'bottom',
      trigger: 'mouseenter focus click',
    });
  }
}
