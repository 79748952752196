import { Controller } from "stimulus";
import Chart from 'chart.js';
import formatDollars from "../../stylesheets/utility/format_dollars";

export default class WholeFarmIncomeAndExpensesController extends Controller {
  static targets = [
    "chart",
    "year",
  ];
  chart;

  connect() {
    this.chart = new Chart(this.chartTarget.getContext('2d'), { type: "bar" });
    this.redrawChart();
  }

  //
  // Update the data and labels and call chart.update()
  // for a smooth transition
  //
  redrawChart() {
    this.chart.data = this.getChartData();
    this.chart.options = {
      scales: {
        yAxes: [{
          ticks: {
            callback: (value) => formatDollars(value, { wholeNumber: true })
          }
        }]
      },
      tooltips: {
        callbacks: {
          label: (tooltipItems) => formatDollars(tooltipItems.yLabel.toString()),
          title: (tooltipItems, data) => {
            // use the dataset label for tooltip labels
            let dataset = data.datasets[tooltipItems[0].datasetIndex]
            return dataset.label
          }
        },
      }
    };
    this.chart.update();
  }

  selectedYears() {
    return this.yearTargets.filter(r => r.checked).map(el => el.value);
  }

  getChartData() {
    const data = JSON.parse(this.data.get("report-data"));
    // Filter by selected years
    const years = this.selectedYears();
    data.labels = data.labels.filter(label => years.indexOf(label) >= 0);
    data.datasets.forEach(dataset => {
      dataset.data = dataset.data.filter(d => years.indexOf(d.x) >= 0)
    });
    return data;
  }
}
