import { Controller } from "stimulus";

//
// Usage:
//
// <body data-controller="mobile-navigation" data-target="mobile-navigation.container">
//   <button data-action="click->mobile-navigation#open">Open nav</p>
//   <button data-action="click->mobile-navigation#close">Close nav</p>
// </div>
//
//
export default class MobileNavigationController extends Controller {
    static targets = [
        "container",
    ];

    open(e){
        e.preventDefault();
        this.containerTarget.classList.add("layout-open")
    }

    close(e){
        e.preventDefault();
        this.containerTarget.classList.remove("layout-open")
    }

}
