import { Controller } from "stimulus";
import formatDollars from "../stylesheets/utility/format_dollars";

export default class IncomePatchController extends Controller {
  static targets = [
    "income",
    "tonnes",
    "perTonne",
  ];

  connect() {
    this.updatePerTonne()
  }

  calculatePerTon(){
    let tonnes = parseFloat(this.tonnesTarget.value);
    tonnes = tonnes || 1; // avoid divide-by-zero
    let income = parseFloat(this.incomeTarget.value);
    return income / tonnes;
  }

  updatePerTonne(){
    this.perTonneTarget.innerText = formatDollars(this.calculatePerTon());
  }
}
