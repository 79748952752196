//
// Formats a number to a human-readable dollar value,
// e.g. 5000 -> $5,000, using javascript's internationalization API
//
//
// Options:
//
//   wholeNumber:
//     Determine whether whole numbers should be formatted as $50 (rather than $50.00).
//     Numbers with decimals will still have decimals (e.g. $50.55)
//
//   per:
//     Format the dollar figure per unit (e.g. tonne, ha, ml), e.g. formatDollars(100, { per: "tonne" })
//
//
const perUnits = {
  ha: 'Ha',
  ml: 'ML',
};

export default function formatDollars(n, { wholeNumber = false, per = null } = {}){
  if(isNaN(n)){
    return "-"
  }

  let options = {
    style: 'currency',
    currency: 'AUD',
  };
  // if it's a whole number, don't format with a decimal
  if(wholeNumber && n % 1 === 0){
    options.minimumFractionDigits = 0;
    options.maximumFractionDigits = 0;
  }
  // format as dollar
  const formatter = new Intl.NumberFormat('en-AU', options);
  let string = formatter.format(n);
  // add per units, e.g. $40/tonne
  if(per && per !== "total") {
    const unit = perUnits[per] || per;
    string = string + "/" + unit
  }

  return string
}
