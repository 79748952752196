import {Controller} from "stimulus";

export default class XeroMappableController extends Controller {

  static classes = [
    "active",
  ]
  static values = {
    mappableId: String,
    mappableType: String,
  }
  static targets = [
    "hiddenField",
  ]

  connect() {
    this.updateClass()
  }

  updateClass() {
    if (this.isActive()) {
      this.element.classList.add(this.activeClass);
    } else {
      this.element.classList.remove(this.activeClass);
    }
  }

  isActive() {
    return this.hiddenFieldTarget.value === "1"
  }

  toggle(event) {
    event.preventDefault()

    // toggle active status
    this.hiddenFieldTarget.value = this.isActive() ? "0" : "1"
    this.updateClass()

    // create event which can be handled by outer (mappings) controller.
    // inspired by https://github.com/hotwired/stimulus/issues/200#issuecomment-434731830
    // not sure if this is the right approach
    const toggleEvent = new CustomEvent('toggle-mappable', {
      detail: {
        mappableId: this.mappableIdValue,
        mappableType: this.mappableTypeValue,
        active: this.active,
      }
    })
    this.element.dispatchEvent(toggleEvent)
  }
}
