import { Controller } from "stimulus";

export default class DashboardController extends Controller {
  static targets = [
    "tab",
    "tabPane"
  ];

  connect() {
    if(this.tabTargets.length > 0) {
      this.goToTabIndex(0)
    }
  }

  goToTab(e){
    e.preventDefault();
    const tabIndex = this.tabTargets.indexOf(e.target);
    this.goToTabIndex(tabIndex);
  }

  goToTabIndex(tabIndex){
    this.tabTargets.forEach((el, i) => {
      // highlight the tab
      el.parentNode.classList.toggle("dashboard--tabs--selected",i === tabIndex);
      // show or hide the pane
      this.tabPaneTargets[i].classList.toggle("dashboard--tab-pane--hidden", i !== tabIndex)
    })
  }
}
