import { Controller } from "stimulus";

//
// Usage:
//
// <div data-controller="toggle">
//   <p data-action="click->toggle#toggle">Click me</p>
//   <p data-target="toggle.body" class="toggle__hide">Now I'm visible!</p>
// </div>
//
// You can also provide an icon target and an icon class, which will be toggled
// on that target when toggle is fired.
//
export default class ToggleController extends Controller {
  static targets = [
    "header",
    "body",
    "icon",
  ];

  toggle(){
    if(this.hasIconTarget) {
      this.iconTarget.classList.toggle(this.data.get("icon-class"));
    }
    this.bodyTarget.classList.toggle("toggle__hide")
  }
}
