import { Controller } from "stimulus";
import Chart from 'chart.js';
import formatDollars from "../../stylesheets/utility/format_dollars";

export default class VarietalIncomeAndCostsController extends Controller {
  static targets = [
    "measure",
    "chart",
    "year",
    "patch",
  ];
  chart;

  connect() {
    this.chart = new Chart(this.chartTarget.getContext('2d'), { type: "bar" });
    this.redrawChart();
  }

  //
  // Update the data and labels and call chart.update()
  // for a smooth transition
  //
  redrawChart() {
    this.chart.data = this.getChartData();
    this.chart.options = {
      scales: {
        yAxes: [{
          ticks: {
            callback: (value) => formatDollars(value)
          }
        }]
      },
      tooltips: {
        callbacks: {
          label: (tooltipItems) => formatDollars(tooltipItems.yLabel.toString(), { per: this.selectedMeasure() })
        }
      }
    };
    this.chart.update();
  }

  selectedMeasure(){
    return this.measureTargets.find(r => r.checked).value;
  }

  selectedPatches(){
    return this.patchTargets.filter(el => el.checked).map(el => el.value);
  }

  selectedYear() {
    return this.yearTargets.find(r => r.checked).value;
  }

  //
  // Build the data for chart.js, e.g.
  //
  // {
  //   labels: ["Patch 1", "Patch 2"],
  //   datasets: [
  //     {
  //       label: "Income",
  //       backgroundColor: "#1f77b4",
  //       data: [2468,234]
  //     },
  //     {
  //       label: "Fixed & Variable Expenses",
  //       backgroundColor: "#aec7e8",
  //       data: [2412.62,703.38]
  //     }
  //   ]
  // }

  //
  getChartData() {
    const data = JSON.parse(this.data.get("report-data"));
    const yearData = data[this.selectedYear()];
    const datasets = yearData.map(d => ({
      label: d.key,
      backgroundColor: d.colour,
      data: d.values.filter(v => this.selectedPatches().indexOf(v.x) >= 0)
        .map(v => parseFloat(v[this.selectedMeasure()]))
    }));

    return {
      labels: this.selectedPatches(),
      datasets: datasets
    };
  }
}
