import { Controller } from "stimulus";
import sumElementValues from "../stylesheets/utility/sum_element_values";
import formatDollars from "../stylesheets/utility/format_dollars";

export default class IncomeController extends Controller {
  static targets = [
    "leasedWaterIncome",
    "patchIncome",
    "totalFormatted",
    "otherIncome",
  ];

  connect() {
    this.updateTotals();
  }

  calculateLeasedWaterIncome() {
    return sumElementValues(this.leasedWaterIncomeTargets);
  }

  calculatePatchesIncome() {
    return sumElementValues(this.patchIncomeTargets);
  }

  calculateOtherIncome() {
    return sumElementValues(this.otherIncomeTargets);
  }

  updateTotals() {
    const total = this.calculateLeasedWaterIncome() + this.calculatePatchesIncome() + this.calculateOtherIncome();
    this.totalFormattedTarget.innerText = formatDollars(total);
  }
}
