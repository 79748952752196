import { Controller } from "stimulus";
import sumElementValues from "../stylesheets/utility/sum_element_values";
import formatDollars from "../stylesheets/utility/format_dollars";

export default class ExpensesController extends Controller {
  static targets = [
    "fixedExpense",
    "variableExpense",
    "fixedExpensesTotalFormatted",
    "variableExpensesTotalFormatted",
    "totalFormatted",
  ];

  connect() {
    this.updateTotals();
  }

  updateTotals(){
    const fixedTotal = sumElementValues(this.fixedExpenseTargets);
    const variableTotal = sumElementValues(this.variableExpenseTargets);
    const total = fixedTotal + variableTotal;

    this.fixedExpensesTotalFormattedTargets.forEach(e => e.innerText = formatDollars(fixedTotal));
    this.variableExpensesTotalFormattedTargets.forEach(e => e.innerText = formatDollars(variableTotal));
    this.totalFormattedTarget.innerText = formatDollars(total);
  }

}
