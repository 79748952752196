import { Controller } from "stimulus";
import sumElementValues from "../stylesheets/utility/sum_element_values";
import formatDollars from "../stylesheets/utility/format_dollars";

export default class ExpensesBreakdownController extends Controller {
  static targets = [
    "patchExpense",
    "calculatedTotalFormatted",
    "remainderWrapper",
    "remainderFormatted",
  ];

  connect() {
    this.updateTotals();
  }

  updateTotals(){
    let specifiedTotal  = parseFloat(this.data.get("specified-total"));
    let calculatedTotal = sumElementValues(this.patchExpenseTargets);
    let remainder       = specifiedTotal - calculatedTotal;

    this.calculatedTotalFormattedTarget.innerText = formatDollars(calculatedTotal);
    this.remainderFormattedTarget.innerText = formatDollars(remainder);
    this.remainderWrapperTarget.classList.toggle("table--error", remainder != 0);
  }
}
