import { Controller } from "stimulus";
import Chart from 'chart.js';
import formatDollars from "../../stylesheets/utility/format_dollars";

export default class VarietalIncomeAndCostsByYearController extends Controller {
  static targets = [
    "measure",
    "chart",
    "year",
    "variety",
  ];
  chart;

  connect() {
    this.chart = new Chart(this.chartTarget.getContext('2d'), { type: "bar" });
    this.redrawChart();
  }

  //
  // Update the data and labels and call chart.update()
  // for a smooth transition
  //
  redrawChart() {
    this.chart.data = this.getChartData();
    this.chart.options = {
      scales: {
        yAxes: [{
          ticks: {
            callback: (value) => formatDollars(value),
            beginAtZero: true,
          }
        }],
      },
      tooltips: {
        callbacks: {
          label: (tooltipItems) => formatDollars(tooltipItems.yLabel.toString(), { per: this.selectedMeasure() })
        }
      }
    };
    this.chart.update();
  }

  selectedMeasure(){
    return this.measureTargets.find(r => r.checked).value;
  }

  selectedVariety(){
    return this.varietyTargets.find(el => el.checked).value;
  }

  selectedYears() {
    return this.yearTargets.filter(r => r.checked).map(el => el.value);
  }

  //
  // Build the data for chart.js, e.g.
  //
  // {
  //   labels: ["2019-20", "2018-19"],
  //   datasets: [
  //     {
  //       label: "Income",
  //       backgroundColor: "#1f77b4",
  //       data: [2468,     234]
  //     },
  //     {
  //       label: "Fixed & Variable Expenses",
  //       backgroundColor: "#aec7e8",
  //       data: [2412.62, 703.38]
  //     }
  //   ]
  // }

  //
  getChartData() {
    const data = JSON.parse(this.data.get("report-data"));
    const variety = this.selectedVariety();
    const years = this.selectedYears();
    const measure = this.selectedMeasure();

    let buildRow = (dataset) =>
      years.map(year =>
        data
          .filter(d => d.year === year && d.variety === variety)
          .map(d => d[dataset][measure])
          .reduce((a, b) => a + b, 0));

    return {
      labels: years,
      datasets: [
        {
          label: "Income",
          backgroundColor: "#1f77b4",
          data: buildRow('income')
        },
        {
          label: "Fixed & Variable Expenses",
          backgroundColor: "#aec7e8",
          data: buildRow('expenses')
        }
      ]
    };
  }
}
