//
// Takes a list of html input elements (for example, stimulus targets)
// and sums the values as floats
//
export default function sumElementValues(elements) {
  return elements
    .map(element => element.value)
    .map(value => parseFloat(value))
    .filter(n => !isNaN(n))
    .reduce((total, value) => total + value, 0);
}
