import { Controller } from "stimulus";
import Chart from 'chart.js';
import formatDollars from "../../stylesheets/utility/format_dollars";

export default class FixedAndVariableExpensesController extends Controller {
  static targets = [
    "measure",
    "chart",
    "year",
    "patch",
    "variety",
  ];
  chart;

  connect() {
    this.chart = new Chart(this.chartTarget.getContext('2d'), { type: "horizontalBar" });
    this.redrawChart();
  }

  //
  // Update the data and labels and call chart.update()
  // for a smooth transition
  //
  redrawChart() {
    this.chart.data = this.getChartData();
    console.log(this.getChartData());
    this.chart.options = {
      scales: {
        xAxes: [{
          ticks: {
            beginAtZero: true,
            callback: (value) => formatDollars(value, { wholeNumber: true }),
          }
        }]

      },
      tooltips: {
        callbacks: {
          label: (tooltipItems) => formatDollars(tooltipItems.xLabel.toString(), { per: this.selectedMeasure() })
        }
      }
    };
    this.chart.update();
  }

  selectedMeasure(){
    return this.measureTargets.find(r => r.checked).value;
  }

  selectedPatches(){
    return this.patchTargets.filter(el => el.checked).map(el => el.value);
  }

  selectedYear() {
    return this.yearTargets.find(r => r.checked).value;
  }

  selectedVarieties(){
    return this.varietyTargets.filter(el => el.checked).map(el => el.value);
  }

  //
  // Build the data for chart.js, e.g.
  //
  // {
  //   labels: ["Total fixed and variable", "Trellis R & M", "Professional Services"],
  //   datasets: [
  //     {
  //       label: "Expenses",
  //       backgroundColor: "#aec7e8",
  //       data: [15, 12, 11]
  //     }
  //   ]
  // }
  //
  getChartData() {
    const data = JSON.parse(this.data.get("report-data"));
    const yearData = data[this.selectedYear()];
    const measure = this.selectedMeasure();
    const expenses = {};

    // Pulls data out of the yearData for the given
    // variety/patch key and tallies it to expenses
    const addToExpenses = (key) => {
      if(!yearData[key]) {
        return
      }
      let values = yearData[key][0].values;
      values.forEach(value => {
        let expense = value.x;
        if(!expenses[expense]) {
          expenses[expense] = 0;
        }
        expenses[expense] += parseFloat(value[measure])
      })
    };

    this.selectedPatches().forEach(patchId => addToExpenses("patch_" + patchId));
    this.selectedVarieties().forEach(varietyId => addToExpenses("variety_" + varietyId));

    // Sort expenses
    const expenseObjects =
      Object
        .keys(expenses)
        .map(expense => ({ key: expense, value: expenses[expense] }))
        .sort((a, b) => +b.value - +a.value);

    return {
      labels: expenseObjects.map(e => e.key),
      datasets: [{
        label: "Expenses",
        backgroundColor: "#aec7e8",
        data: expenseObjects.map(e => e.value)
      }]
    }
  }
}
