import { Controller } from "stimulus";
import sumElementValues from "../stylesheets/utility/sum_element_values";

export default class WaterUsageController extends Controller {
  static targets = [
    "specifiedTotal",
    "patchWaterUsage",
    "calculatedTotalFormatted",
    "remainderFormatted",
    "remainderWrapper",
  ];

  connect() {
    this.updateTotals();
  }

  calculateTotal(){
    return sumElementValues(this.patchWaterUsageTargets)
  }

  specifiedTotal() {
    return parseFloat(this.specifiedTotalTarget.value);
  }

  //
  // Ensure value only has one decimal place
  //
  ensureOnlyOneDecimal(e) {
    const value = e.target.value;
    if(value.indexOf('.') > 0 && value.split(".")[1].length > 1){
      const valueFloat = parseFloat(value)
      if(!isNaN(valueFloat)) {
        e.target.value = valueFloat.toFixed(1);
      }
    }
  }

  updateTotals(){
    let calculatedTotal = this.calculateTotal();
    let specifiedTotal  = this.specifiedTotal();
    let remainder       = specifiedTotal - calculatedTotal;

    this.calculatedTotalFormattedTarget.innerText = this.formatML(calculatedTotal);
    this.remainderFormattedTarget.innerText = this.formatML(remainder);
    this.remainderWrapperTarget.classList.toggle("table--error", remainder != 0);
  }

  formatML(n) {
    return n + "ML";
  }
}
